<template>
  <base-web-page>
    <template v-slot:main>
         <el-main style="padding:60px 20px;margin:0 auto;width:100%;max-width:1300px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item to="/">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="categoryLink">{{currentCategory.name}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{productName}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row style="margin-top:40px;">
            <el-col :span="12">
                <el-carousel height="500px" :autoplay="false" indicator-position="none" ref="carousel" @change="onImageChange">
                    <el-carousel-item v-for="image in productImages" :key="image.ID">
                        <el-image style="width:80%;" fit="fill" :src="image.image"></el-image>
                    </el-carousel-item>
                </el-carousel>
                <div style="display:-webkit-box;padding:20px;overflow-x:auto;">
                    <div style="margin-right:20px;" v-for="(image, index) in productImages" :key="image.ID">
                        <img v-if="currentImageIndex == index" class="image-checked" style="max-width:80px;height:80px;" :src="image.image" @click="setImageIndex(index)">
                        <img v-else class="image-unchecked" style="max-width:80px;height:80px;" :src="image.image" @click="setImageIndex(index)">
                    </div>
                </div>
            </el-col>
            <el-col :span="12" style="text-align:left;padding-left:40px;">
                <h1 style="margin-top:0px;">{{productName}}</h1>
                <div style="display:-webkit-box;margin-top:-15px;margin-bottom:5px;">
                    <div style="text-align:left;"><el-rate v-model="productReviewScore" disabled disabled-void-color="#C6D1DE"></el-rate></div>
                    <div style="font-size:15px;">{{productReviewCount}} reviews</div>
                </div>
                <div style="font-size:23px;">
                    <span style="color:#F56C6C;text-decoration:line-through;margin-right:10px;">${{(productPrice/100).toFixed(2)}}</span><span>${{(productMemberPrice/100).toFixed(2)}}</span>
                </div>
                <el-divider></el-divider>
                <div>
                    <div><span style="font-weight:bold;font-size:14px;margin-right:10px;">Choose -</span><span style="font-size:14px;">{{productSkuName}}</span></div>
                    <div style="display:-webkit-box;margin-top:10px;">
                        <div v-for="(sku, index) in productSkus" :key="sku.ID" @click="skuChoose(index)" style="cursor:pointer;">
                            <span  v-if="index == currentSkuIndex" class="image-checked" style="padding:10px;margin-right:10px;display:block;">{{sku.name}}</span>
                            <span  v-else class="image-unchecked" style="padding:10px;display:block;margin-right:10px;">{{sku.name}}</span>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 30px;">
                    <div style="font-weight:bold;font-size:14px;margin-right:10px;margin-bottom:10px;">Quantity</div>
                    <el-input-number v-model="quantityNum" size="mini" :min="1" :max="100"></el-input-number>
                </div>
                <div style="margin-top: 40px;">
                     <el-button plain :loading="addToCartLoading" @click="addToCartClick" style="width:100%;font-weight:bold;">Add To Cart</el-button>
                </div>
            </el-col>
        </el-row>
        <el-card shadow="never" style="margin-top:40px;">
          <div slot="header" class="clearfix">
            <div style="text-align:left;font-weight:bold;">Customer Reviews</div>
            <div style="display:-webkit-box;margin-top:20px;">
                <div style="border-right:1px solid #E4E7ED;padding-right:20px;">
                    <review-stat-item :value="value5" :percentage="perStar5" :count="countStar5"></review-stat-item>
                    <review-stat-item :value="value4" :percentage="perStar4" :count="countStar4"></review-stat-item>
                    <review-stat-item :value="value3" :percentage="perStar3" :count="countStar3"></review-stat-item>
                    <review-stat-item :value="value2" :percentage="perStar2" :count="countStar2"></review-stat-item>
                    <review-stat-item :value="value1" :percentage="perStar1" :count="countStar1"></review-stat-item>
                </div>
                <div style="margin-left:20px;text-align:left;">
                    <el-rate v-model="value5" disabled disabled-void-color="#C6D1DE"></el-rate>
                    <div style="font-size:14px;margin-top:5px;">Based on {{productReviewCount}} reviews</div>
                </div>
            </div>
          </div>
        </el-card>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import ModelProduct from '../viewmodel/ModelProduct.vue'
import reviewStatItem from '../../components/widgets/ReviewStatItem.vue'

export default{
    extends: ModelProduct,
    name: 'product',
    components: {
        BaseWebPage,
        reviewStatItem
    }
}
</script>

<style>

</style>