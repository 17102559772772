<template>
  <div id="reviewStatItem" style="display:-webkit-box;">
      <div>
        <el-rate v-model="value" disabled disabled-void-color="#C6D1DE"></el-rate>
      </div>
      <div style="width:200px;">
        <el-progress :text-inside="true" :stroke-width="16" :percentage="percentage" status="warning"></el-progress>
      </div>
      <div style="font-size:14px;margin-left:10px;">({{count}})</div>
  </div>
</template>

<script>
export default {
    name: 'reviewStatItem',
    props:['value', 'percentage', 'count']
}
</script>

<style>

</style>